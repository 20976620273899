import React from 'react'
import { graphql } from 'gatsby'
import Errors from '../components/errors'
import Page from '../templates/page'

export const query = graphql`
  query FrontpageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)frontpage/" }) {
      ...PageInfo
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      openGraph {
        title
        description
        image {
          ...SanityImage
        }
      }
    }
  }
`

const IndexPage = props => {
  const { data, errors } = props

  if (errors) {
    return <Errors errors={errors} />
  }

  return (
    <>
      <Page data={data} isHome />
    </>
  )
}

export default IndexPage
